import smartPlayerEmbedIFrameSelector from './smart-player-embed-iframe-selector';

var _customEnterCallback;
var _customExitCallback;

var internalFullFrameMode = function(activate, embeddedEl) {
   if (activate) {
      embeddedEl.classList.add('smart-player-full-frame-mode');
   } else {
      embeddedEl.classList.remove('smart-player-full-frame-mode');
   }
};

var findEmbeddedEl = function(iFrameEl) {
   if (!iFrameEl) {
      iFrameEl = smartPlayerEmbedIFrameSelector.findFirstSmartPlayerIFrameEl();
   }

   if (iFrameEl) {
      // check for the responsive embed method
      if (iFrameEl.parentNode && iFrameEl.parentNode.classList.contains('smart-player-embed-container')) {
         return iFrameEl.parentNode;
      }
      return iFrameEl;
   }
   throw new Error('TSC.fullFrameMode::findEmbeddedEl(): Could not find an embedded Smart Player on the page.');
};

export default {
   setCustomEnterCallback: function(callback) {
      if (callback === undefined || typeof callback === 'function') {
         _customEnterCallback = callback;
      } else {
         throw new Error('TSC.fullFrameMode::setCustomEnterCallback(): Callback must be a function.');
      }
   },
   setCustomExitCallback: function(callback) {
      if (callback === undefined || typeof callback === 'function') {
         _customExitCallback = callback;
      } else {
         throw new Error('TSC.fullFrameMode::setCustomExitCallback(): Callback must be a function.');
      }
   },
   enter: function(iFrameEl) {
      var embeddedEl = findEmbeddedEl(iFrameEl);
      if (_customEnterCallback) {
         _customEnterCallback.call(this, embeddedEl);
      } else {
         internalFullFrameMode(true, embeddedEl);
      }
   },
   exit: function(iFrameEl) {
      var embeddedEl = findEmbeddedEl(iFrameEl);
      if (_customExitCallback) {
         _customExitCallback.call(this, embeddedEl);
      } else {
         internalFullFrameMode(false, embeddedEl);
      }
   }
};
