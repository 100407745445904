import $ from 'jquery';

var windowWrapper = {
   getLocationHref: function() {
      return window.location.href;
   },
   getLocation: function() {
      return window.location;
   },
   getNavigator: function() {
      return window.navigator;
   },
   getWindow: function() {
      return window;
   },
   getDocument: function(wrapInJQuery) {
      return wrapInJQuery ? $(window.document) : window.document;
   },
   open: function(url, features) {
      if (features) {
         window.open(url, features);
      } else {
         window.open(url);
      }
   }
};

export default windowWrapper;
