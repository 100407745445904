export default {
   findFirstSmartPlayerIFrameEl: function() {
      var iFrameEl;
      var iFramesInDocument;
      if (document.querySelectorAll) {
         iFramesInDocument = document.querySelectorAll('.smart-player-embed-container-iframe');
      } else {
         iFramesInDocument = document.getElementsByClassName('smart-player-embed-container-iframe');
      }
      if (iFramesInDocument.length > 0) {
         iFrameEl = iFramesInDocument[0];
      }
      return iFrameEl;
   }
};
