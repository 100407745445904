import smartPlayerEmbedIFrameSelector from './smart-player-embed-iframe-selector';
import fullFrameModeController from './full-frame-mode-controller';
import windowWrapper from '../common/window-wrapper';

var sendMessageToSmartPlayer = function(smartPlayerIFrameEl, eventName, data) {
   if (smartPlayerIFrameEl) {
      var message = {};
      message.smartPlayerEventName = eventName;
      if (data) {
         message.data = data;
      }
      smartPlayerIFrameEl.contentWindow.postMessage(window.JSON.stringify(message), '*');
   }
};

var sendUrlParamsToSmartPlayerEmbed = function(iFrameEl) {
   if (iFrameEl) {
      var eventName = 'PARENT-URL-PARAMS';
      var windowLocationUrl = windowWrapper.getLocationHref();
      var embeddedParams = windowLocationUrl.slice(windowLocationUrl.indexOf('?') + 1);
      sendMessageToSmartPlayer(iFrameEl, eventName, embeddedParams);
   }
};

var onFullFrameMessage = function(iFrameEl, activate) {
   if (activate) {
      fullFrameModeController.enter(iFrameEl);
   } else {
      fullFrameModeController.exit(iFrameEl);
   }
};

var smartPlayerMessage = function(e) {
   // filter out non smart player events
   if (!e.data || typeof e.data.indexOf !== 'function' || e.data.indexOf('smartPlayerEventName') === -1) {
      return;
   }
   var message = window.JSON.parse(e.data);
   var iFrameEl = message.iFrameId ? document.getElementById(message.iFrameId) : undefined;

   switch (message.smartPlayerEventName) {
      case 'ENTER-FULLFRAME': {
         onFullFrameMessage(iFrameEl, true);
         break;
      }
      case 'EXIT-FULLFRAME': {
         onFullFrameMessage(iFrameEl, false);
         break;
      }
      case 'GET-EMBEDDED-URL-VARS': {
         sendUrlParamsToSmartPlayerEmbed(iFrameEl);
         break;
      }
   }
};

window.addEventListener('message', smartPlayerMessage, false);

export default {
   sendEventToSmartPlayer: function(eventName, data) {
      sendMessageToSmartPlayer(smartPlayerEmbedIFrameSelector.findFirstSmartPlayerIFrameEl(), eventName, data);
   }
};
